import axios, { AxiosResponse } from 'axios'
import { handleError } from '../util/Functions'
import { RootStore } from '../util/stores/RootStore'

/* Request handle */
export const getUserRecords = async (root: RootStore) => {
    /* Init */
    let response: AxiosResponse

    /* Urls */
    const url = root.cloud.host + '/api/userrecord/?format=json'
    console.debug('Trying to get user records from: ' + url)

    /* Request handle */
    try {
        response = await axios.get(url, {
            headers: {
                Authorization: 'Token ' + root.user.token,
                'Content-Type': 'application/json',
            },
            timeout: root.data.timeOutGet,
        })

        const userRecords = response.data.results.slice(0, 1) // single user record for now (last updated, as set in API)
        root.data.setUserRecords(userRecords)
        console.debug('Loaded userRecord', userRecords)
    } catch (error) {
        handleError(root, error)
    }
}
