import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { IonButton, IonContent, IonInput, IonPage, IonToast } from '@ionic/react'
import { Logo } from '../components/Logo'
import { PageHeader } from '../components/PageHeader'
import { postSupportMessage } from '../requests/SupportMessage'
import { useStores } from '../util/Functions'

const useStyles = createUseStyles({
    content: {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    container: {
        flex: '0 1 400px',
        textAlign: 'center',
    },
    input: {
        margin: '20px 0',
        padding: '0 5px 0 10px !important',
        border: '1px solid #ccc',
        borderRadius: 5,
    },
})

export const Support: React.FC = observer(() => {
    /* Load */
    const { root, ui } = useStores()
    const classes = useStyles()

    /* Init form  variables*/
    const [message, setMessage] = useState<string>()

    /* Init form */
    const formData = {
        message: message,
    }

    /* Handle submit */
    const handleSubmitForm = async () => {
        await postSupportMessage(root, formData)
        setToastMessage(ui.postResponseText)
        setShowToast(true)
        setMessage('')
    }

    /* Page behaviour */
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState<string>()

    const pageid = 'Support'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={false} pagetitle={`Support`} />
            <IonContent>
                <div className={classes.content}>
                    <div className={classes.container}>
                        <Logo />
                        <IonInput
                            className={classes.input}
                            value={message}
                            placeholder="Enter your support message"
                            onIonChange={(e: any) => setMessage(e.detail.value!)}
                        />
                        <IonButton
                            type="submit"
                            onClick={() => {
                                handleSubmitForm()
                            }}
                        >
                            Submit
                        </IonButton>
                    </div>
                </div>
            </IonContent>
            <IonToast
                isOpen={showToast}
                duration={3000}
                message={toastMessage}
                onDidDismiss={() => {
                    setShowToast(false)
                    ui.setPostResponseText('')
                }}
            />
        </IonPage>
    )
})
