import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
    IonButton,
    IonCol,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonToast,
} from '@ionic/react'
import { PageHeader } from '../components/PageHeader'
import { getDevices, patchDeviceDescription } from '../requests/Devices'
import { useStores } from '../util/Functions'

export const DeviceEdit: React.FC = observer(() => {
    /* Load */
    const { root, ui } = useStores()
    const history = useHistory()
    const params: any = useParams()

    /* Init form  variables*/
    const [description, setDescription] = useState<string>()

    /* Init form */
    const formData = {
        description: description,
    }

    /* Handle submit */
    const handleSubmitForm = async () => {
        setButtonDisabled(true)
        await patchDeviceDescription(root, params.deviceid, formData)
        setToastMessage(ui.postResponseText)
        setShowToast(true)
        getDevices(root)
        history.push('/')
        setButtonDisabled(false)
    }

    // page behaviour
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState<string>()
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

    const pageid = 'DeviceEdit'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={false} pagetitle={`Edit Device`} />
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonLabel position="fixed" color="primary">
                            Description
                        </IonLabel>
                        <IonInput
                            value={description}
                            placeholder="here.."
                            onIonChange={(e: any) => setDescription(e.detail.value!)}
                        />
                    </IonItem>
                </IonList>
                <IonRow>
                    <IonCol>
                        <IonButton
                            type="submit"
                            disabled={buttonDisabled}
                            expand="block"
                            onClick={() => {
                                handleSubmitForm()
                            }}
                        >
                            Submit
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonContent>
            <IonToast
                isOpen={showToast}
                duration={10000}
                message={toastMessage}
                onDidDismiss={() => {
                    setShowToast(false)
                    ui.setPostResponseText('')
                }}
            />
        </IonPage>
    )
})
