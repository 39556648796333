import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { Redirect, Route } from 'react-router-dom'
import {
    homeOutline,
    rainyOutline,
    skullOutline,
    thermometerOutline,
    waterOutline,
} from 'ionicons/icons'

import { About } from '../pages/About'
import { CarbonDioxide } from '../pages/CarbonDioxide'
import { DeviceDetail } from '../pages/DeviceDetail'
import { DeviceEdit } from '../pages/DeviceEdit'
import { DeviceList } from '../pages/DeviceList'
import { DewPoint } from '../pages/DewPoint'
import { Home } from '../pages/Home'
import { Humidity } from '../pages/Humidity'
import React from 'react'
import { SignIn } from '../pages/SignIn'
import { Signup } from '../pages/Signup'
import { Stream } from '../pages/Stream'
import { Support } from '../pages/Support'
import { Temperature } from '../pages/Temperature'
import { UpdateDetail } from '../pages/UpdateDetail'
import { Updates } from '../pages/Updates'
import { createUseStyles } from 'react-jss'
import { observer } from 'mobx-react'
import { useStores } from '../util/Functions'

const useStyles = createUseStyles({
    tabs: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        paddingTop: 5,
        display: 'flex',
        flexDirection: 'row-reverse',
        '& ion-tab-button': {
            opacity: 0.7,
            '&.tab-selected': {
                opacity: 1,
            },
        },
    },
})

export const Routes: React.FC = observer(() => {
    const { user } = useStores()
    const classes = useStyles()

    return (
        <IonTabs>
            <IonRouterOutlet id="main">
                <Route path="/" exact>
                    {user.authenticated ? <Home /> : <SignIn />}
                </Route>
                <Route path="/auth" exact>
                    <SignIn />
                </Route>
                <Route path="/signup" exact>
                    <Signup />
                </Route>
                <Route path="/support" exact>
                    {user.authenticated ? <Support /> : <SignIn />}
                </Route>
                <Route path="/devices" exact>
                    {user.authenticated ? <DeviceList /> : <SignIn />}
                </Route>
                <Route path="/devices/:deviceid" exact>
                    {user.authenticated ? <DeviceDetail /> : <SignIn />}
                </Route>
                <Route path="/devices/:deviceid/edit" exact>
                    {user.authenticated ? <DeviceEdit /> : <SignIn />}
                </Route>
                <Route path="/temperature" exact>
                    {user.authenticated ? <Temperature /> : <SignIn />}
                </Route>
                <Route path="/humidity" exact>
                    {user.authenticated ? <Humidity /> : <SignIn />}
                </Route>
                <Route path="/dewpoint" exact>
                    {user.authenticated ? <DewPoint /> : <SignIn />}
                </Route>
                <Route path="/carbondioxide" exact>
                    {user.authenticated ? <CarbonDioxide /> : <SignIn />}
                </Route>
                <Route path="/updates" exact>
                    {user.authenticated ? <Updates /> : <SignIn />}
                </Route>
                <Route path="/updates/:updateid" exact>
                    {user.authenticated ? <UpdateDetail /> : <SignIn />}
                </Route>
                <Route path="/stream" exact>
                    {user.authenticated ? <Stream /> : <SignIn />}
                </Route>
                <Route path="/about">{user.authenticated ? <About /> : <SignIn />}</Route>
                <Route>
                    <Redirect to="/" />
                </Route>
            </IonRouterOutlet>

            <IonTabBar
                color="light"
                slot="bottom"
                hidden={!user.authenticated}
                className={classes.tabs}
            >
                <IonTabButton tab="carbondioxide" href="/carbondioxide">
                    <IonIcon icon={skullOutline} />
                    <IonLabel>CO2</IonLabel>
                </IonTabButton>
                <IonTabButton tab="dewpoint" href="/dewpoint">
                    <IonIcon icon={rainyOutline} />
                    <IonLabel>Dew Point</IonLabel>
                </IonTabButton>
                <IonTabButton tab="humidity" href="/humidity">
                    <IonIcon icon={waterOutline} />
                    <IonLabel>Humidity</IonLabel>
                </IonTabButton>
                <IonTabButton tab="temperature" href="/temperature">
                    <IonIcon icon={thermometerOutline} />
                    <IonLabel>Temperature</IonLabel>
                </IonTabButton>
                <IonTabButton tab="home" href="/">
                    <IonIcon icon={homeOutline} />
                    <IonLabel>Home</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    )
})
