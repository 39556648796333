import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react'
import { DeviceHeader } from '../components/DeviceSummary'
import { NoContent } from '../components/NoContent'
import { PageHeader } from '../components/PageHeader'
import { useStores } from '../util/Functions'
import { ModelDevice } from '../util/Models'

export const DeviceDetail: React.FC = observer(() => {
    // load
    const { data } = useStores()
    const params: any = useParams()

    // init
    let device: ModelDevice = {
        id: '',
        created: '',
        description: '',
        ip: '',
        port: '',
        page: '',
        local: false,
        online: false,
        peripherals: [],
    }
    let recordsForDevice: any = null
    let deviceExists: boolean = false
    let recordsForDeviceExist: boolean = false

    // condition 1
    if (data.devices.length > 0) {
        let devices: ModelDevice[] = data.devices.filter(
            (item) => item.id.toString() === params.deviceid
        )
        deviceExists = devices.length === 1
        if (deviceExists) {
            device = devices[0]
        }
    }

    // condition 2
    if (deviceExists && data.liveRecords.length > 0) {
        let recordsForDeviceFilter = data.liveRecords.filter((item) => item.device === device.id)
        recordsForDeviceExist = recordsForDeviceFilter.length > 0
        if (recordsForDeviceExist) {
            recordsForDevice = recordsForDeviceFilter.sort((a, b) => {
                return a.variable.localeCompare(b.variable)
            })
        }
    }

    // page
    const pageid = 'DeviceDetail'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={false} pagetitle={`Device info`} />
            <IonContent>
                {deviceExists ? <DeviceHeader device={device} /> : <NoContent />}
                {recordsForDeviceExist ? (
                    <IonCard>
                        <IonCardContent>
                            <IonGrid>
                                {recordsForDevice.map((liveRecord) => (
                                    <IonRow key={liveRecord.variable + liveRecord.time}>
                                        <IonCol>{liveRecord.time}</IonCol>
                                        <IonCol>{liveRecord.variable} </IonCol>
                                        <IonCol>{liveRecord.value}</IonCol>
                                    </IonRow>
                                ))}
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                ) : null}
            </IonContent>
        </IonPage>
    )
})
