import { action, computed, observable } from 'mobx'
import { RootStore } from './RootStore'

export class CloudStore {
    @observable hostBase: string = 'homeserver.dallen.dev'
    @observable connectionFailed: boolean = false

    @action setHostBase(value: string) {
        this.hostBase = value
    }

    @action setConnectionFailed(value: boolean) {
        this.connectionFailed = value
    }

    @computed get host(): string {
        const prefix = this.hostBase.includes(':') ? 'http://' : 'https://'
        return `${prefix}${this.hostBase}/django`
    }

    @computed get websocketURL(): string {
        const scheme = this.hostBase.includes(':') ? 'ws://' : 'wss://'
        return `${scheme}${this.hostBase}/django/stream/ws/${this.root.data.wstoken}/`
    }

    constructor(public root: RootStore) {
        if (process.env.REACT_APP_HOMESERVER_HOST) {
            this.setHostBase(process.env.REACT_APP_HOMESERVER_HOST)
        }
        console.log('Connected to host:', this.hostBase)
    }
}
