import './Menu.scss'

import {
    cloudDownloadOutline,
    helpCircleOutline,
    informationCircleOutline,
    logIn,
    logOut,
    notificationsOutline,
    personAdd,
    radioOutline,
} from 'ionicons/icons'
import { observer } from 'mobx-react'
import React from 'react'
import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
} from '@ionic/react'
import { useStores } from '../util/Functions'

const menuItems = {
    loggedInPages: [
        { title: 'Support', path: '/support', icon: helpCircleOutline },
        { title: 'Logout', path: '/auth', icon: logOut },
    ],
    loggedOutPages: [
        { title: 'Login', path: '/auth', icon: logIn },
        { title: 'Signup', path: '/signup', icon: personAdd },
    ],
    appPages: [
        { title: 'Devices', path: '/devices', icon: radioOutline },
        { title: 'Updates', path: '/updates', icon: notificationsOutline },
        { title: 'Stream', path: '/stream', icon: cloudDownloadOutline },
        { title: 'About', path: '/about', icon: informationCircleOutline },
    ],
}

interface Pages {
    title: string
    path: string
    icon: string
    routerDirection?: string
}

export const Menu: React.FC = observer(() => {
    const { user } = useStores()

    function renderListItems(list: Pages[]) {
        return (
            list
                // .filter(route => !!route.path)
                .map((page) => (
                    <IonMenuToggle key={page.title} auto-hide="false">
                        <IonItem detail={false} routerLink={page.path}>
                            <IonIcon slot="start" icon={page.icon} />
                            <IonLabel>{page.title}</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                ))
        )
    }

    return (
        <IonMenu type="overlay" disabled={false} contentId="main">
            <IonContent forceOverscroll={false}>
                <IonList lines="none">
                    <IonListHeader>User</IonListHeader>
                    {user.authenticated
                        ? renderListItems(menuItems.loggedInPages)
                        : renderListItems(menuItems.loggedOutPages)}
                </IonList>
                <IonList lines="none">
                    <IonListHeader>Homesystem</IonListHeader>
                    {renderListItems(menuItems.appPages)}
                </IonList>
            </IonContent>
        </IonMenu>
    )
})
