import {
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonInput,
    IonPage,
    IonToast,
} from '@ionic/react'
import React, { useState } from 'react'

import Cookies from 'universal-cookie'
import { Logo } from '../components/Logo'
import { PageHeader } from '../components/PageHeader'
import { createUseStyles } from 'react-jss'
import { postCredentials } from '../requests/Credentials'
import { useHistory } from 'react-router-dom'
import { useStores } from '../util/Functions'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
    },
    logo: {
        flex: 1,
        marginTop: 80,
        textAlign: 'center',
        fontSize: '8em',
    },
    form: {
        flex: 1,
        maxWidth: 400,
    },
    button: {
        margin: '0 0 20px 0',
        width: '100%',
    },
    input: {
        margin: '20px 0',
        padding: '0 5px 0 10px !important',
        border: '1px solid #ccc',
        borderRadius: 5,
    },
    logout: {
        textAlign: 'center',
    },
    logoutButton: {
        marginTop: 20,
    },
})

export const SignIn: React.FC = () => {
    // init
    const { root, user, ui } = useStores()
    const classes = useStyles()
    const history = useHistory()
    const cookies = new Cookies()

    // init form  variables
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    // init form
    const formData = {
        username: username,
        password: password,
    }

    // page behaviour
    const [buttonLoginDisabled, setButtonLoginDisabled] = useState<boolean>(false)
    const [buttonLogoutDisabled, setButtonLogoutDisabled] = useState<boolean>(false)

    // handles
    const handleLogin = async () => {
        // avoid repetitive button clicks
        console.debug('disable login button')
        setButtonLoginDisabled(true)
        setButtonLogoutDisabled(true)

        // handle  login
        setPassword('')
        user.setUsername(username)
        user.clearTokens()
        await postCredentials(root, formData)

        // proceed if successfully obtained token (assuming token is correct)
        console.debug(`User is authenticated: ${user.authenticated}`)
        if (user.authenticated) {
            // cache token
            let cachedToken = cookies.get('accesstoken')
            console.info(`Just cached token: '${cachedToken}'`)
        }

        // reenable buttons
        console.debug('enable login button')
        setButtonLoginDisabled(false)
        setButtonLogoutDisabled(false)
    }

    const handleLogout = async () => {
        // avoid repetitive clicks
        console.debug('disable login button')
        setButtonLogoutDisabled(true)

        // clear cookies
        console.debug(`Cookies: ${cookies.getAll()}`)
        cookies.remove('accesstoken')
        cookies.remove('csrftoken')
        console.debug(`Cookies: ${cookies.getAll()}`)

        // clear tokens
        user.clearTokens()

        // proceed
        history.replace('/')

        // reenable button
        console.debug('enable login button')
        setButtonLogoutDisabled(false)

        // clear storage
        localStorage.clear()
        sessionStorage.clear()
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') handleLogin()
    }

    //login and logout components
    const login = (
        <React.Fragment>
            <IonInput
                value={username}
                className={classes.input}
                onIonChange={(e: any) => setUsername(e.detail.value!)}
                onKeyDown={handleKeyPress}
                placeholder="Username"
                autocomplete="username"
            />
            <IonInput
                value={password}
                className={classes.input}
                type="password"
                onIonChange={(e: any) => setPassword(e.detail.value!)}
                onKeyDown={handleKeyPress}
                placeholder="Password"
            />
            <IonButton
                className={classes.button}
                disabled={buttonLoginDisabled}
                onClick={() => handleLogin()}
                size="large"
            >
                Log in
            </IonButton>
            <IonButton
                fill="outline"
                className={classes.button}
                onClick={() => history.push('/signup/')}
            >
                Sign up
            </IonButton>
        </React.Fragment>
    )

    const logout = (
        <div className={classes.logout}>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle> Logged in </IonCardTitle>
                    <IonCardSubtitle> {user.token} </IonCardSubtitle>
                </IonCardHeader>
            </IonCard>
            <IonButton
                className={classes.logoutButton}
                disabled={buttonLogoutDisabled}
                color="danger"
                onClick={() => handleLogout()}
            >
                Log out
            </IonButton>
        </div>
    )

    const pageid = 'Authentication'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={false} pagetitle={`Sign In`} />
            <IonContent>
                <div className={classes.container}>
                    <Logo />
                    <div className={classes.form}>{user.authenticated ? logout : login}</div>
                </div>

                <IonToast
                    isOpen={ui.showToast}
                    onDidDismiss={() => ui.hideToast()}
                    message={ui.toastText}
                    duration={3000}
                />
            </IonContent>
        </IonPage>
    )
}
