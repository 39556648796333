import { action, computed, observable } from 'mobx'
import { RootStore } from './RootStore'

/* Data store, containing all the client side user data */
export class UserStore {
    @computed get authenticated() {
        return this.token !== null
    }
    @computed get Authorization() {
        return { Authorization: `Token ${this.token}` }
    }

    @observable language: string = 'en'
    @observable username: string = ''
    @observable password: string = ''
    @observable token: string | null = null
    @observable hidePassword: boolean = true
    @observable registerSucces: boolean = false

    @action clearTokens() {
        this.token = null
        this.root.data.setUserRecords([])
    }

    @action setLanguage(value: string) {
        this.language = value
    }

    @action setToken(value: string) {
        this.token = value
        this.root.loadAllData()
    }

    @action setUsername(value: string) {
        this.username = value
    }

    @action setPassword(value: string) {
        this.password = value
    }

    @action setHidePassword(value: boolean) {
        this.hidePassword = value
    }

    @action setRegisterSucces(value: boolean) {
        this.registerSucces = value
    }

    constructor(public root: RootStore) {}
}
