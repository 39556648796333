import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import React from 'react'
import {
    HiChevronDoubleUp,
    HiChevronDoubleDown,
    HiMinus,
    HiChevronDown,
    HiChevronUp,
} from 'react-icons/hi2'
import { createUseStyles } from 'react-jss'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { useStores } from '../util/Functions'
import { ModelDevice, ModelLiveRecord, ModelLiveVariable } from '../util/Models'
import { NoContent } from './NoContent'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flex: '0 1',
    },
    detail: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 1 580px',
        '@media (min-width:580px)': {
            fontSize: '1.4em',
        },
    },
    right: {
        textAlign: 'right',
    },
    gripperContainer: {
        margin: 20,
        flex: 0,
        display: 'flex',
        justifyContent: 'center',
        '@media (min-width:580px)': {
            margin: 40,
        },
    },
    gripper: {
        marginTop: 10,
        height: 5,
        width: 50,
        borderRadius: 2,
        '@media (min-width:580px)': {
            marginTop: 20,
        },
    },
    trendIcon: {
        fontSize: '1.2em',
        opacity: 0.8,
    },
})

interface DetailProps {
    variable: string
    liveRecords: ModelLiveRecord[]
}

const Detail: React.FC<DetailProps> = observer((props) => {
    // load data
    const { data } = useStores()
    const classes = useStyles()

    // init
    let liveVariablesToRender: any = null
    let liveVariables: ModelLiveVariable[] = []
    let liveRecordsVar: ModelLiveRecord[] = []

    // get live records for specific variable
    liveRecordsVar = data.liveRecords.filter((item: ModelLiveRecord) => {
        return item.variable === props.variable
    })

    // match live records with devices -> store as live variable = device + liverecord
    liveRecordsVar.forEach((liveRecord) => {
        let device: ModelDevice = data.devices.filter((item: ModelDevice) => {
            return item.id === liveRecord.device
        })[0]
        let liveVariable: ModelLiveVariable = {
            device: device,
            liveRecord: liveRecord,
        }
        liveVariables.push(liveVariable)
    })

    // sort by description, so the rows don't keep flipping in the app
    let liveVariablesSorted = liveVariables.sort((a, b) => {
        return a.device?.description.localeCompare(b.device.description)
    })

    let unit = ' °C'
    if (props.variable === 'Humidity') {
        unit = '%'
    } else if (props.variable === 'CarbonDioxide') {
        unit = ' ppm'
    }

    const getTrendIcon = (slope: number | null, variable: string) => {
        const thresholds = {
            temperature: {
                up1: 0.2,
                up2: 1.0,
                down1: -0.2,
                down2: -1.0,
            },
            humidity: {
                up1: 0.5,
                up2: 2.0,
                down1: -0.5,
                down2: -2.0,
            },
            dewPoint: {
                up1: 0.2,
                up2: 1.0,
                down1: -0.2,
                down2: -1.0,
            },
            carbonDioxide: {
                up1: 2.0,
                up2: 4.0,
                down1: -2.0,
                down2: -4.0,
            },
        }

        let icon
        if (slope === null) {
            return null
        } else if (slope < thresholds[variable].down2) {
            icon = <HiChevronDoubleDown />
        } else if (slope < thresholds[variable].down1) {
            icon = <HiChevronDown />
        } else if (slope > thresholds[variable].up2) {
            icon = <HiChevronDoubleUp />
        } else if (slope > thresholds[variable].up1) {
            icon = <HiChevronUp />
        } else {
            icon = <HiMinus />
        }

        return <span className={classes.trendIcon}>{icon}</span>
    }

    // render elements
    if (liveVariablesSorted) {
        liveVariablesToRender = liveVariablesSorted.map((liveVariable) => {
            const timestamp = DateTime.fromISO(liveVariable.liveRecord.time)

            let trendIcon: any = null
            if (props.variable === 'Humidity') {
                const trend = data.getHumidityTrend(liveVariable.device.id)
                trendIcon = getTrendIcon(trend, 'humidity')
            } else if (props.variable === 'Temperature') {
                const trend = data.getTemperatureTrend(liveVariable.device.id)
                trendIcon = getTrendIcon(trend, 'temperature')
            } else if (props.variable === 'DewPoint') {
                const trend = data.getDewPointTrend(liveVariable.device.id)
                trendIcon = getTrendIcon(trend, 'dewPoint')
            } else if (props.variable === 'CarbonDioxide') {
                const trend = data.getCarbonDioxideTrend(liveVariable.device.id)
                trendIcon = getTrendIcon(trend, 'carbonDioxide')
            }

            return (
                <IonRow
                    key={liveVariable.device.description}
                    hidden={!liveVariable.device.online}
                    style={{ padding: '4px 0', fontFamily: 'monospace' }}
                >
                    <IonCol>
                        {trendIcon} {liveVariable.device.description}
                    </IonCol>
                    <IonCol className={classes.right}>
                        {liveVariable.liveRecord.value.toFixed(1)}
                        {unit}
                    </IonCol>
                    <IonCol className={classes.right}>
                        {timestamp.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
                    </IonCol>
                </IonRow>
            )
        })
    }

    return <IonGrid style={{ margin: 10 }}>{liveVariablesToRender}</IonGrid>
})

interface LiveDetailProps {
    variable: string
}

export const LiveDetail: React.FC<LiveDetailProps> = observer((props) => {
    const { data, ui } = useStores()
    const classes = useStyles()

    const variable = props.variable
    let liveRecords: ModelLiveRecord[] = []
    let variableExists: boolean = false

    if (data.liveRecords) {
        liveRecords = data.liveRecords.filter((item) => item.variable === variable)
        variableExists = liveRecords.length > 0
    }

    return (
        <div className={classes.container}>
            <div className={classes.detail}>
                <div className={classes.gripperContainer}>
                    <div
                        className={classes.gripper}
                        style={{
                            background: ui.isDark ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
                        }}
                    />
                </div>
                {variableExists ? (
                    <Detail liveRecords={liveRecords} variable={variable} />
                ) : (
                    <NoContent />
                )}
            </div>
        </div>
    )
})
