import { observer } from 'mobx-react'
import React from 'react'
import { useState } from 'react'
import {
    IonButton,
    IonContent,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonPage,
} from '@ionic/react'
import { CompUpdate } from '../components/CompUpdate'
import { PageHeader } from '../components/PageHeader'
import { getUpdates, patchUpdatesAllRead } from '../requests/Updates'
import { sleep, useStores } from '../util/Functions'
import { ModelUpdate } from '../util/Models'

export const Updates: React.FC = observer(() => {
    // load mobx stores
    const { root, data } = useStores()

    // render updates only if there are any
    let updatesToRender: any
    if (data.updates.length > 0) {
        updatesToRender = data.updates.map((update: ModelUpdate) => (
            <CompUpdate update={update} key={update.id} />
        ))
    }

    // handles
    const handleButtonClearUpdates = async () => {
        console.debug('disable button')
        setButtonDisabled(true)
        await patchUpdatesAllRead(root)
        await getUpdates(root)
        await sleep(100)
        console.debug('enable button')
        setButtonDisabled(false)
    }

    // page behaviour
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

    const pageid = 'Updates'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={false} pagetitle={`Updates`} />
            <IonContent fullscreen={true}>
                <IonItemGroup>
                    <IonItemDivider>
                        <IonLabel>My updates</IonLabel>
                        <IonButton
                            disabled={buttonDisabled}
                            slot="end"
                            onClick={() => {
                                handleButtonClearUpdates()
                            }}
                        >
                            Mark all read
                        </IonButton>
                    </IonItemDivider>
                    <IonList>{updatesToRender}</IonList>
                </IonItemGroup>
            </IonContent>
        </IonPage>
    )
})
