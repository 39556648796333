import { observer } from 'mobx-react'
import React from 'react'
import {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { useStores } from '../util/Functions'
import { WebsocketButton } from './WebsocketButton'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    toolbar: {
        '--padding-top': '8px',
        '--padding-bottom': '4px',
        '--background': 'var(--ion-toolbar-background)',
    },
})
interface PageHeaderProps {
    pageid: string
    pagemain: boolean
    pagetitle: string
}

export const PageHeader: React.FC<PageHeaderProps> = observer((props) => {
    const { user } = useStores()
    const classes = useStyles()

    return (
        <IonHeader hidden={!user.authenticated} className="ion-no-border">
            <IonToolbar className={classes.toolbar}>
                <IonButtons slot="start">
                    {props.pagemain ? (
                        <IonMenuButton color="dark" />
                    ) : (
                        <IonBackButton color="dark" />
                    )}
                </IonButtons>
                <IonTitle ion-text-center>{props.pagetitle}</IonTitle>
                <IonButtons slot="end">
                    <WebsocketButton />
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
})
