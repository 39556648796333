import React from 'react'
import { RiHomeWifiFill } from 'react-icons/ri'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    logo: {
        flex: 1,
        marginTop: 80,
        textAlign: 'center',
        fontSize: '8em',
    },
})

export const Logo = () => {
    const classes = useStyles()
    return (
        <div className={classes.logo}>
            <RiHomeWifiFill />
        </div>
    )
}
