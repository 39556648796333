import { MobXProviderContext } from 'mobx-react'
import React from 'react'

/* General Mobx loading function */
export const useStores = () => {
    return React.useContext(MobXProviderContext)
}

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const handleError = (root, error) => {
    // init
    console.error(error)
    let statusText = ': '

    // handle
    if (!error.response) {
        root.cloud.setConnectionFailed(true)
        root.ui.setToastText('No response from server')
        console.error('Client: No response from server')
    } else {
        console.error(error.response)
        statusText = error.response.statusText + ' - '

        if (error.response.status >= 400 && error.response.status < 500) {
            if (error.response.data) {
                console.error(error.response.data)

                for (let x in error.response.data) {
                    statusText += ' ' + x + ': ' + error.response.data[x]
                }
                root.ui.setToastText(statusText)
                console.error(statusText)
            } else {
                root.ui.setToastText('Invalid credentials provided')
                console.error('Client: Invalid credentials provided')
            }
        } else {
            console.error(error.response)
            root.ui.setToastText(error.response)
            console.error(error.response)
        }
    }

    // return statustext
    return statusText
}

// Get the Object's methods names:
export const getMethodsNames = (obj) => {
    return Object.keys(obj).filter((key) => typeof obj[key] === 'function')
}

export const makeid = (length) => {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export const forceSWupdate = () => {
    console.debug('Service worker force updated')
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (let registration of registrations) {
                registration.update()
            }
        })
    }
}

export const addMonths = (date, months) => {
    const d = date.getDate()
    date.setMonth(date.getMonth() + +months)
    if (date.getDate() !== d) {
        date.setDate(0)
    }
    return date
}

export const usePageVisibility = () => {
    const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden())
    const onVisibilityChange = () => setIsVisible(getIsDocumentHidden())

    React.useEffect(() => {
        const visibilityProp = getBrowserVisibilityProp()

        if (visibilityProp) {
            document.addEventListener(visibilityProp, onVisibilityChange, false)
        }
        return () => {
            if (visibilityProp) {
                document.removeEventListener(visibilityProp, onVisibilityChange)
            }
        }
    })

    return isVisible
}

interface BrowserDocument extends Document {
    msHidden?: boolean
    webkitHidden?: boolean
}

const getBrowserVisibilityProp = () => {
    const doc: BrowserDocument = document
    if (typeof doc.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        return 'visibilitychange'
    } else if (typeof doc.msHidden !== 'undefined') {
        return 'msvisibilitychange'
    } else if (typeof doc.webkitHidden !== 'undefined') {
        return 'webkitvisibilitychange'
    }
}

const getBrowserDocumentHiddenProp = () => {
    const doc: BrowserDocument = document
    if (typeof doc.hidden !== 'undefined') {
        return 'hidden'
    } else if (typeof doc.msHidden !== 'undefined') {
        return 'msHidden'
    } else if (typeof doc.webkitHidden !== 'undefined') {
        return 'webkitHidden'
    }
}

const getIsDocumentHidden = () => {
    const hiddenProp = getBrowserDocumentHiddenProp()
    if (hiddenProp) {
        return !document[hiddenProp]
    } else {
        return false
    }
}

export const findLineByLeastSquares = (valuesX: number[], valuesY: number[]) => {
    let sum_x = 0
    let sum_y = 0
    let sum_xy = 0
    let sum_xx = 0
    let count = 0

    let x = 0
    let y = 0
    let valuesLength = valuesX.length

    if (valuesLength === 0) {
        return {
            m: null,
            b: null,
        }
    }

    for (let v = 0; v < valuesLength; v++) {
        x = valuesX[v]
        y = valuesY[v]
        sum_x += x
        sum_y += y
        sum_xx += x * x
        sum_xy += x * y
        count++
    }

    // Calculate m and b
    const m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x)
    const b = sum_y / count - (m * sum_x) / count

    return {
        m: m,
        b: b,
    }
}
