// Import General

import '@ionic/react/css/core.css'
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'
import './theme.scss'

import { Provider } from 'mobx-react'
import React from 'react'
import { IonApp } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Menu } from './navigation/Menu'
import { Routes } from './navigation/Routes'
import { RootStore } from './util/stores/RootStore'
import { setupIonicReact } from '@ionic/react'

// Setup ionic-react
setupIonicReact()

// Init empty Mobx store
const root = new RootStore()

// General app
const App = () => {
    // check for cached login token
    const cachedToken = root.cookies.get('accesstoken')
    if (cachedToken) {
        console.debug(`Using cached accesstoken: '${cachedToken}'`)
        root.user.setToken(cachedToken)
    } else {
        console.debug('No cached accesstoken')
    }

    // Return general app, source ionic app
    return (
        <Provider root={root} data={root.data} cloud={root.cloud} ui={root.ui} user={root.user}>
            <IonApp>
                <IonReactRouter>
                    <Menu />
                    <Routes />
                </IonReactRouter>
            </IonApp>
        </Provider>
    )
}

// Export general app
export default App
