import { IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react'

import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../util/Functions'

export const Refresher = observer(() => {
    const { root } = useStores()

    const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setTimeout(() => {
            root.loadAllData()
            event.detail.complete()
        }, 2000)
    }

    return (
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent ion-text-center></IonRefresherContent>
        </IonRefresher>
    )
})
