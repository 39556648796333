import { observer } from 'mobx-react'
import React from 'react'
import {
    IonContent,
    IonGrid,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonPage,
    IonRow,
} from '@ionic/react'
import { PageHeader } from '../components/PageHeader'
import { useStores } from '../util/Functions'
import { ModelStreamMessage } from '../util/Models'

interface specificStreamMessage {
    streamMessage: ModelStreamMessage
}

export const StreamItem: React.FC<specificStreamMessage> = observer((specific) => {
    return (
        <IonItem>
            <IonGrid>
                <IonRow>{specific.streamMessage.type}</IonRow>
                <IonRow>{specific.streamMessage.message}</IonRow>
            </IonGrid>
        </IonItem>
    )
})

export const Stream: React.FC = observer(() => {
    // load
    const { data } = useStores()

    // render streamMessages only if there are any
    let streamMessagesToRender: any
    if (data.streamMessages.length > 0) {
        streamMessagesToRender = data.streamMessages.map((streamMessage: ModelStreamMessage) => (
            <StreamItem streamMessage={streamMessage} key={streamMessage.message} />
        ))
    }

    /* Page */
    const pageid = 'Stream'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={false} pagetitle={`Stream`} />
            <IonContent fullscreen={true}>
                <IonItemGroup>
                    <IonItemDivider>
                        <IonLabel>Stream messages</IonLabel>
                    </IonItemDivider>
                    {streamMessagesToRender}
                </IonItemGroup>
            </IonContent>
        </IonPage>
    )
})
