import axios, { AxiosResponse } from 'axios'
import { handleError } from '../util/Functions'
import { RootStore } from '../util/stores/RootStore'

/* Request handle */
export const getUpdates = async (root: RootStore) => {
    /* Init */
    let response: AxiosResponse

    /* Urls */
    const url = root.cloud.host + '/api/update/?format=json'
    console.debug('Trying to get updates from: ' + url)

    /* Request handle */
    try {
        response = await axios.get(url, {
            headers: {
                Authorization: 'Token ' + root.user.token,
                'Content-Type': 'application/json',
            },
            timeout: root.data.timeOutGet,
        })
        const updates = response.data.results
        // console.debug(updates)
        root.data.setUpdates(updates)
        console.debug(`Loaded update list with ${root.data.updates.length} items`)
    } catch (error) {
        handleError(root, error)
    }
}

/* Request handle */
export const patchUpdateRead = async (root: RootStore, updateid: string) => {
    /* Init */
    let response: AxiosResponse

    /* Url */
    const url = root.cloud.host + '/api/update/' + updateid + '/'
    console.log('Trying to patch update as read to ' + url)

    /* Request handle */
    try {
        response = await axios.patch(
            url,
            {
                read: true,
            },
            {
                headers: {
                    Authorization: 'Token ' + root.user.token,
                    'Content-Type': 'application/json',
                },
                timeout: root.data.timeOutPost,
            }
        )
        console.debug(response)
    } catch (error) {
        handleError(root, error)
    }
}

/* Request handle */
export const patchUpdatesAllRead = async (root: RootStore) => {
    /* Init */
    let response: AxiosResponse

    /* Urls */
    const url = root.cloud.host + '/api/update/'
    console.debug('Trying to patch updates as all read from: ' + url)

    /* Request handle */
    try {
        response = await axios.patch(
            url,
            {},
            {
                headers: {
                    Authorization: 'Token ' + root.user.token,
                    'Content-Type': 'application/json',
                },
                timeout: root.data.timeOutGet,
            }
        )
        console.debug(response)
        let updates = response.data.results
        console.debug(updates)
    } catch (error) {
        handleError(root, error)
    }
}
