import { DateTime } from 'luxon'
import { action, computed, observable, remove } from 'mobx'
import { getDevices } from '../../requests/Devices'
import { getLiveRecords } from '../../requests/LiveRecords'
import { getRecords } from '../../requests/Records'
import { getUpdates } from '../../requests/Updates'
import { findLineByLeastSquares } from '../Functions'
import {
    ModelDevice,
    ModelRecord,
    ModelLiveRecord,
    ModelStreamMessage,
    ModelUpdate,
    ModelUserRecord,
} from '../Models'
import { RootStore } from './RootStore'

/* Data store, containing all the data loaded from the API */
export class DataStore {
    @computed get wstoken() {
        if (this.userRecords.length > 0) {
            return this.userRecords[0].wstoken
        } else {
            return 'no_wstoken'
        }
    }

    @computed get updatesUnreadN() {
        let updatesUnread: ModelUpdate[] = []
        this.updates.forEach((update: ModelUpdate, index) => {
            if (!update.read) {
                updatesUnread.push(update)
            }
        })
        return updatesUnread.length
    }

    @computed get uniqueVariables() {
        let allVar: string[] = []
        this.liveRecords.forEach((liverecord: ModelLiveRecord) => {
            allVar.push(liverecord.variable)
        })
        var uniqueVar = allVar.filter((v, i, a) => a.indexOf(v) === i).sort()
        return uniqueVar
    }

    @observable timeOutGet: number = 5000
    @observable timeOutPost: number = 10000

    @action setTimeOutGet(value: number) {
        this.timeOutGet = value
    }

    @action setTimeOutPost(value: number) {
        this.timeOutPost = value
    }

    @observable devices: ModelDevice[] = []
    @observable temperatureRecords: Map<string, ModelRecord[]> = new Map()
    @observable humidityRecords: Map<string, ModelRecord[]> = new Map()
    @observable dewPointRecords: Map<string, ModelRecord[]> = new Map()
    @observable carbonDioxideRecords: Map<string, ModelRecord[]> = new Map()

    @observable recordsBuffer: ModelLiveRecord[] = []
    @observable liveRecords: ModelLiveRecord[] = []
    @observable streamMessages: ModelStreamMessage[] = []
    @observable updates: ModelUpdate[] = []
    @observable updatesUnread: ModelUpdate[] = []
    @observable userRecords: ModelUserRecord[] = []

    @action setDevices(value: ModelDevice[]) {
        this.devices = value
    }

    @computed get deviceDescription() {
        let descMap = new Map()
        for (const d of this.devices) {
            descMap.set(d.id, d.description)
        }
        return descMap
    }

    @computed get deviceIds() {
        return this.root.data.devices.map((device) => device.id)
    }

    getTemperatureTrend = (device: string) => {
        const deviceRecords = this.temperatureRecords.get(device)
        return this.getTrend(deviceRecords)
    }

    getHumidityTrend = (device: string) => {
        const deviceRecords = this.humidityRecords.get(device)
        return this.getTrend(deviceRecords)
    }

    getDewPointTrend = (device: string) => {
        const deviceRecords = this.dewPointRecords.get(device)
        return this.getTrend(deviceRecords)
    }

    getCarbonDioxideTrend = (device: string) => {
        const deviceRecords = this.carbonDioxideRecords.get(device)
        return this.getTrend(deviceRecords)
    }

    getTrend = (deviceRecords: ModelRecord[] | undefined) => {
        const timeWindow = DateTime.now().minus({ hours: 1 })
        const filteredRecords = deviceRecords?.filter(
            (record: ModelRecord) => record.timestamp > timeWindow
        )

        let x: number[] = []
        let y: number[] = []
        if (filteredRecords) {
            filteredRecords?.forEach((record: ModelRecord) => {
                x.push(record.timestamp.ts / 3600000) // hours
                y.push(record.value)
            })

            const { m } = findLineByLeastSquares(x, y)
            return m
        } else {
            return null
        }
    }

    @action setRecords(value: ModelLiveRecord[]) {
        this.recordsBuffer.push(...value)
    }

    @action updateRecords() {
        this.temperatureRecords.clear()
        this.humidityRecords.clear()
        this.dewPointRecords.clear()
        this.carbonDioxideRecords.clear()

        for (const record of this.recordsBuffer) {
            const timestamp = DateTime.fromISO(record.time)
            if (record.variable === 'Temperature') {
                const deviceRecords = this.temperatureRecords.has(record.device)
                    ? this.temperatureRecords.get(record.device)!
                    : []
                this.temperatureRecords.set(record.device, [
                    ...deviceRecords,
                    {
                        timestamp: timestamp,
                        value: record.value,
                    },
                ])
            } else if (record.variable === 'Humidity') {
                const deviceRecords = this.humidityRecords.has(record.device)
                    ? this.humidityRecords.get(record.device)!
                    : []
                this.humidityRecords.set(record.device, [
                    ...deviceRecords,
                    {
                        timestamp: timestamp,
                        value: record.value,
                    },
                ])
            } else if (record.variable === 'DewPoint') {
                const deviceRecords = this.dewPointRecords.has(record.device)
                    ? this.dewPointRecords.get(record.device)!
                    : []
                this.dewPointRecords.set(record.device, [
                    ...deviceRecords,
                    {
                        timestamp: timestamp,
                        value: record.value,
                    },
                ])
            } else if (record.variable === 'CarbonDioxide') {
                const deviceRecords = this.carbonDioxideRecords.has(record.device)
                    ? this.carbonDioxideRecords.get(record.device)!
                    : []
                this.carbonDioxideRecords.set(record.device, [
                    ...deviceRecords,
                    {
                        timestamp: timestamp,
                        value: record.value,
                    },
                ])
            }
        }

        this.recordsBuffer = []
    }

    @action setLiveRecords(value: ModelLiveRecord[]) {
        this.root.cloud.setConnectionFailed(false)
        this.liveRecords = value
    }

    @action setUpdates(value: ModelUpdate[]) {
        this.updates = value
    }

    @action setupdatesUnread(value: ModelUpdate[]) {
        this.updatesUnread = value
    }

    @action setUserRecords(value: ModelUserRecord[]) {
        this.userRecords = value
    }

    @action setLiveRecord(liveRecord) {
        // collect indices of liverecords that have the id of the live record
        const indicesRemove: any = []
        this.liveRecords.forEach((x, index) => {
            if (x.id === liveRecord['id']) {
                indicesRemove.push(index)
            }
        })

        // remove old record(s) by index
        if (indicesRemove.length > 0) {
            remove(this.liveRecords, indicesRemove)
        }

        // add new record
        this.liveRecords.unshift(liveRecord)
    }

    @action addStreamMessage(value: ModelStreamMessage) {
        this.streamMessages.push(value)
        if (this.streamMessages.length > 8) {
            this.streamMessages.shift()
        }
    }

    @observable websocket: WebSocket = new WebSocket(this.root.cloud.websocketURL)

    @action refreshWebsocket = async () => {
        if (this.wstoken === 'no_wstoken') {
            console.debug('No token for websocket', this.wstoken)
        } else {
            // refresh on every config
            await this.websocket.close()
            await console.debug('Refresh websocket stream: ' + this.root.cloud.websocketURL)
            await (this.websocket = new WebSocket(this.root.cloud.websocketURL))

            // process streamed message based on type
            this.websocket.onmessage = (e) => {
                // console.debug(e.data)
                let wsdata = JSON.parse(e.data)
                switch (wsdata.type) {
                    case 'live_record':
                        const liveRecord = JSON.parse(wsdata['message'])
                        this.root.data.setLiveRecord(liveRecord)
                        break
                    case 'hint_refresh':
                        const hint = wsdata['message']
                        this.funRefresh(hint)
                        break
                }

                // main stream (simple string messages)
                wsdata['message'] = JSON.stringify(wsdata['message'])
                this.root.data.addStreamMessage(wsdata)
            }

            // on open
            this.websocket.onopen = (e) => {
                this.root.ui.setColourWebsocket('success')
                console.debug('User websocket opened..')
            }

            // on error
            this.websocket.onerror = (e) => {
                this.root.ui.setColourWebsocket('danger')
                console.debug('User websocket error..', e)
            }

            // on close
            this.websocket.onclose = (e) => {
                this.root.ui.setColourWebsocket('danger')
                console.debug('closing: code ' + e.code)
            }
        }
    }

    @action funRefresh(pageid: string) {
        switch (pageid) {
            case 'Temperature':
                getRecords(this.root)
                break
            case 'Humidity':
                getRecords(this.root)
                break
            case 'DewPoint':
                getRecords(this.root)
                break
            case 'CarbonDioxide':
                getRecords(this.root)
                break
            case 'Devices':
                console.debug('refresh devices, settings, updates')
                getUpdates(this.root)
                getDevices(this.root)
                break
            case 'DeviceDetail':
                this.root.ui.setNeedsRefresh(true)
                this.refreshWebsocket()
                console.debug('refresh specific device, settings and stream')
                break
            case 'Updates':
                getUpdates(this.root)
                console.debug('refresh updates')
                break
            case 'Stream':
                this.root.data.refreshWebsocket()
                console.debug('refresh stream')
                break
            default:
                getLiveRecords(this.root)
        }
    }

    constructor(public root: RootStore) {}
}
