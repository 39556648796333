import React from 'react'
import { IonItem, IonLabel } from '@ionic/react'
import { observer } from 'mobx-react'
import { ModelUpdate } from '../util/Models'

interface propsUpdate {
    update: ModelUpdate
}

export const CompUpdate: React.FC<propsUpdate> = observer((specific) => {
    return (
        <IonItem routerLink={`/updates/${specific.update.id}`} key={specific.update.id}>
            <IonLabel>
                {specific.update.read ? (
                    <h3>{specific.update.title}</h3>
                ) : (
                    <h3>
                        <b>{specific.update.title}</b>
                    </h3>
                )}
                <p>{specific.update.detail}</p>
            </IonLabel>
        </IonItem>
    )
})
