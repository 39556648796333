import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonText,
    IonTitle,
} from '@ionic/react'
import { NoContent } from '../components/NoContent'
import { PageHeader } from '../components/PageHeader'
import { getUpdates, patchUpdateRead } from '../requests/Updates'
import { useStores } from '../util/Functions'
import { ModelUpdate } from '../util/Models'

interface specificUpdate {
    update: ModelUpdate
}

const Detail: React.FC<specificUpdate> = observer((specific) => {
    // load mobx stores
    const { root } = useStores()

    // patch update as read and reload updates
    if (!specific.update.read) {
        patchUpdateRead(root, specific.update.id.toString())
        getUpdates(root)
    }

    return (
        <IonList>
            <IonListHeader>
                <IonLabel>
                    <IonTitle>{specific.update.title}</IonTitle>
                </IonLabel>
            </IonListHeader>
            <IonItem>
                <IonText>{specific.update.detail}</IonText>
            </IonItem>
        </IonList>
    )
})

export const UpdateDetail: React.FC = observer(() => {
    // load
    const { data } = useStores()
    const params: any = useParams()

    // init
    let update: any = null
    let specificUpdateExists: boolean = false

    // condition
    if (data.updates.length > 0) {
        let updates = data.updates.filter((item) => item.id.toString() === params.updateid)
        specificUpdateExists = updates.length === 1
        if (specificUpdateExists) {
            update = updates[0]
        }
    }

    // page
    const pageid = 'UpdateDetail'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={false} pagetitle={`Update`} />
            <IonContent>
                {specificUpdateExists ? <Detail update={update} /> : <NoContent />}
            </IonContent>
        </IonPage>
    )
})
