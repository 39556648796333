import { DateTime } from 'luxon'
import { HiMiniSignalSlash } from 'react-icons/hi2'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonGrid,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonRow,
    IonText,
    IonTitle,
} from '@ionic/react'
import { useStores } from '../util/Functions'
import { ModelDevice, ModelLiveRecord, ModelPeripheral } from '../util/Models'
import { CompSetting } from './CompSetting'

const useStyles = createUseStyles({
    card: {
        margin: 10,
        '@media (max-height:580px)': {
            margin: 5,
        },
        flex: '1 1 50%',
        maxWidth: 400,
        width: '100%',
        boxShadow: 'none',
        border: '2px solid rgba(100, 100, 100, 0.3)',
    },
    cardHeader: {
        fontSize: '1.2em',
        fontWeight: 600,
        display: 'flex',
        padding: '15px 20px 5px 20px',
        flexDirection: 'row',
        '@media (max-height:580px)': {
            padding: '8px 20px 0 20px',
        },
    },
    cardContent: {
        display: 'flex',
        padding: '0 20px 5px 20px',
        '@media (max-height:580px)': {
            padding: '0 20px',
        },
    },
    title: {
        flex: 1,
        textAlign: 'left',
        marginLeft: 5,
        '@media (max-height:580px)': {
            fontSize: '0.9em',
        },
    },
    timestamp: {
        flex: 1,
        textAlign: 'right',
        fontWeight: 400,
        '@media (max-height:580px)': {
            fontSize: '0.9em',
        },
    },
    unit: {
        marginLeft: 2,
        opacity: 0.6,
        fontSize: '0.5em',
        verticalAlign: 'super',
    },
    icon: {
        opacity: 0.4,
        fontSize: '0.6em',
        marginLeft: 4,
    },
    value: {
        textAlign: 'left',
        fontSize: '1.8em',
    },
    temperature: {
        flex: 1,
        textAlign: 'left',
    },
    humidity: {
        flex: 1,
        textAlign: 'right',
    },
    carbonDioxide: {
        flex: 1,
        textAlign: 'right',
    },
    offlineIcon: {
        marginLeft: 5,
        display: 'inline-block',
    },
})

interface DeviceSummaryProps {
    device: ModelDevice
}

export const DeviceSummary: React.FC<DeviceSummaryProps> = observer((props) => {
    const { data } = useStores()
    const classes = useStyles()

    const [timestamp, setTimestamp] = useState<string>('')

    const records: ModelLiveRecord[] = data.liveRecords.filter(
        (item) => item.device === props.device.id
    )

    let temperature, humidity, carbonDioxide, timestampDT
    for (const r of records) {
        if (r.variable === 'Temperature') {
            temperature = Number(r.value).toFixed(1)
            timestampDT = DateTime.fromISO(r.time)
        } else if (r.variable === 'Humidity') {
            humidity = Number(r.value).toFixed(1)
            timestampDT = DateTime.fromISO(r.time)
        } else if (r.variable === 'CarbonDioxide') {
            carbonDioxide = Number(r.value).toFixed(1)
            timestampDT = DateTime.fromISO(r.time)
        }
    }

    const updateTimestamp = () => {
        if (timestampDT) {
            const now = DateTime.now()
            const elapsed = now.diff(timestampDT, 'seconds').toObject()

            if (elapsed.seconds && elapsed.seconds < 60) {
                setTimestamp('<1 min. ago')
            } else {
                setTimestamp(timestampDT.toRelative({ style: 'short' }))
            }
        }
    }

    if (timestamp === '') updateTimestamp()
    useEffect(() => {
        const refreshTimer = setInterval(() => updateTimestamp(), 15000)
        return () => {
            clearInterval(refreshTimer)
        }
    })

    const carbonDioxideValue = carbonDioxide && (
        <div className={classes.carbonDioxide}>
            <div className={classes.value}>
                <IonText color="dark" className={classes.title}>
                    {Math.round(carbonDioxide)}
                    <span className={classes.unit}>PPM</span>
                </IonText>
            </div>
        </div>
    )

    return (
        <IonCard
            color="light"
            className={classes.card}
            style={{ opacity: props.device.online ? 1 : 0.5 }}
        >
            <IonCardHeader className={classes.cardHeader}>
                <IonText
                    color={props.device.online ? 'medium' : 'danger'}
                    className={classes.title}
                >
                    {props.device.description}
                    {props.device.online ? null : (
                        <div className={classes.offlineIcon}>
                            <HiMiniSignalSlash style={{ flex: 1 }} size={16} color="#D82E5F" />
                        </div>
                    )}
                </IonText>
                {timestamp ? (
                    <IonText color="medium" className={classes.timestamp}>
                        {timestamp}
                    </IonText>
                ) : null}
            </IonCardHeader>
            <IonCardContent className={classes.cardContent}>
                <IonGrid style={{ padding: 0 }}>
                    <IonRow style={{ margin: '0 -5px' }}>
                        <IonCol>
                            <div className={classes.temperature}>
                                <div className={classes.value}>
                                    <IonText color="dark" className={classes.title}>
                                        {temperature}
                                        <span className={classes.unit}>&deg;C</span>
                                    </IonText>
                                </div>
                            </div>
                        </IonCol>
                        <IonCol>
                            <div className={classes.humidity}>
                                <div className={classes.value}>
                                    <IonText color="dark" className={classes.title}>
                                        {humidity}
                                        <span className={classes.unit}>%RH</span>
                                    </IonText>
                                </div>
                            </div>
                        </IonCol>
                        <IonCol>{carbonDioxideValue}</IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
})

interface PeripheralProps {
    peripheral: ModelPeripheral
}

const DetailPeripheral: React.FC<PeripheralProps> = observer((props) => {
    // render only if there are any
    let settingsToRender: any
    console.debug(toJS(props.peripheral))
    if (props.peripheral.settings) {
        console.debug('Rendering settings..')
        settingsToRender = props.peripheral.settings.map((setting) => (
            <CompSetting setting={setting} key={setting.id} />
        ))
    } else {
        console.debug('No peripheral settings to render ')
    }
    return <div>{settingsToRender}</div>
})

interface DeviceHeaderProps {
    device: ModelDevice
}

export const DeviceHeader: React.FC<DeviceHeaderProps> = observer((props) => {
    const history = useHistory()

    // render only if there are any
    let peripheralsToRender: any
    console.debug(toJS(props.device))
    if (props.device.peripherals) {
        // console.debug('Rendering peripherals..')
        peripheralsToRender = props.device.peripherals.map((peripheral) => (
            <DetailPeripheral peripheral={peripheral} key={peripheral.id} />
        ))
    } else {
        console.debug('No device peripherals to render ')
    }

    return (
        <IonItemGroup>
            <IonItemDivider>
                <IonLabel>
                    <IonTitle>{props.device.description}</IonTitle>
                </IonLabel>
                <IonButton
                    slot="end"
                    onClick={() => {
                        history.push(`/devices/${props.device.id}/edit/`)
                    }}
                >
                    Edit
                </IonButton>
            </IonItemDivider>
            {peripheralsToRender}
        </IonItemGroup>
    )
})
