import { pulseOutline } from 'ionicons/icons'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { IonCard, IonCardContent, IonIcon } from '@ionic/react'

const useStyles = createUseStyles({
    card: {
        width: '100%',
        textAlign: 'center',
    },
    content: {
        margin: 10,
    },
    icon: {
        fontSize: '2.2em',
    },
})

export const NoContent = () => {
    const classes = useStyles()

    return (
        <IonCard className={classes.card}>
            <IonCardContent className={classes.content}>
                <IonIcon icon={pulseOutline} className={classes.icon} />
                <div>Waiting for data...</div>
            </IonCardContent>
        </IonCard>
    )
}
