import { IonContent, IonPage } from '@ionic/react'

import { LiveDetail } from '../components/LiveDetail'
import { ModelRecord } from '../util/Models'
import { PageHeader } from '../components/PageHeader'
import { Plot } from '../components/Plot'
import { PlotLoader } from '../components/PlotLoader'
import React from 'react'
import { Refresher } from '../components/Refresher'
import { createUseStyles } from 'react-jss'
import { observer } from 'mobx-react'
import { useStores } from '../util/Functions'

const useStyles = createUseStyles({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    graph: {
        height: '50vh',
    },
})

export const Temperature: React.FC = observer(() => {
    const { data } = useStores()
    const classes = useStyles()

    const records: Map<string, ModelRecord[]> = new Map(data.temperatureRecords)
    const pageid = 'Temperature'

    const pageContent =
        records.size > 0 ? (
            <div className={classes.content}>
                <div className={classes.graph}>
                    <Plot variable="Temperature" unit="°C" records={records} />
                </div>
                <LiveDetail variable="Temperature" />
            </div>
        ) : (
            <PlotLoader />
        )

    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={true} pagetitle={pageid} />
            <IonContent>
                <Refresher />
                {pageContent}
            </IonContent>
        </IonPage>
    )
})
