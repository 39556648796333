import axios, { AxiosResponse } from 'axios'
import Cookies from 'universal-cookie'
import { addMonths, handleError } from '../util/Functions'
import { RootStore } from '../util/stores/RootStore'

/* Request handle */
export const postCredentials = async (root: RootStore, formData: any) => {
    /* Init */
    let response: AxiosResponse

    // Unpack cookies
    const cookies = new Cookies()

    /* Url */
    const url = root.cloud.host + '/api-token-auth/'
    console.debug('Trying to register (post new user to) ' + url)
    console.debug(formData)

    /* Request handle */
    try {
        response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: false,
            timeout: root.data.timeOutPost,
        })
        console.debug(response)

        const accessToken = response.data.token
        console.debug(accessToken)
        root.user.setToken(accessToken)
        let expiryDate = addMonths(new Date(), 2)
        cookies.set('accesstoken', accessToken, { expires: expiryDate, secure: true })
        console.info('Just set accesstoken cookie, expiring at: ', expiryDate)
    } catch (error) {
        handleError(root, error)
    }
}
