import { cloudDownloadOutline, cloudOfflineOutline } from 'ionicons/icons'
import { observer } from 'mobx-react'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { IonIcon } from '@ionic/react'
import { useStores } from '../util/Functions'

const useStyles = createUseStyles({
    content: {
        height: '100%',
        textAlign: 'center',
        opacity: 0.6,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    container: {
        flex: 0,
    },
    icon: {
        fontSize: '3em',
        marginBottom: 10,
    },
})

export const Loading = observer(() => {
    const { cloud } = useStores()
    const classes = useStyles()

    const icon = cloud.connectionFailed ? cloudOfflineOutline : cloudDownloadOutline
    const text = cloud.connectionFailed ? 'Unable to connect' : 'Loading device data...'

    return (
        <div className={classes.content}>
            <div className={classes.container}>
                <IonIcon icon={icon} className={classes.icon} />
                <div>{text}</div>
            </div>
        </div>
    )
})
