import React from 'react'
import { createUseStyles } from 'react-jss'
import { IonContent, IonPage } from '@ionic/react'
import { PageHeader } from '../components/PageHeader'

const useStyles = createUseStyles({
    container: {
        padding: 20,
    },
})

export const About: React.FC = () => {
    const classes = useStyles()

    const pageid = 'About'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={false} pagetitle={`About`} />
            <IonContent fullscreen={true}>
                <div className={classes.container}>
                    <p>
                        This home system consists of a cloud system with a central data base (python
                        django) and a mobile app to access the cloud system (ionic react).
                    </p>
                    <p>The backend is a powerful cloud system, comprising:</p>
                    <ul>
                        <li>a SQL database with content management system (admin tab),</li>
                        <li>
                            a queuing system to set aside demanding tasks, which can leverage the
                            power of python,
                        </li>
                        <li>mqtt support to communicate with most home devices (IoT),</li>
                        <li>websocket support to push data to the mobile app in real time.</li>
                    </ul>
                </div>
            </IonContent>
        </IonPage>
    )
}
