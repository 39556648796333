import { observer } from 'mobx-react'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { useStores } from '../util/Functions'
import { GridLoader } from 'react-spinners'

const useStyles = createUseStyles({
    loadingContainer: {
        width: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

export const PlotLoader = observer(() => {
    const { ui } = useStores()
    const classes = useStyles()

    return (
        <div className={classes.loadingContainer}>
            <GridLoader
                color={ui.isDark ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'}
                loading={true}
                size={18}
            />
        </div>
    )
})
