import { action, observable } from 'mobx'
import { RootStore } from './RootStore'

/* UI store, containing all the user interface variables */
export class UIStore {
    @observable colourWebsocket: string = 'danger'
    @observable loading: boolean = false
    @observable getResponseText: string = 'No response'
    @observable postResponseText: string = 'No response'
    @observable showConnectionError: boolean = false
    @observable toastText: string = ''
    @observable showToast: boolean = false
    @observable needsRefresh: boolean = false
    @observable isDark: boolean = false

    @action setColourWebsocket(value: string) {
        this.colourWebsocket = value
    }

    @action setLoading(value: boolean) {
        this.loading = value
    }

    @action setGetResponseText(value: string) {
        this.getResponseText = value
    }

    @action setPostResponseText(value: string) {
        this.postResponseText = value
    }

    @action setShowConnectionError(value: boolean) {
        this.showConnectionError = value
    }

    @action setNeedsRefresh(value: boolean) {
        this.needsRefresh = value
    }

    @action setToastText(value: string) {
        this.toastText = value
        this.showToast = true
    }

    @action hideToast() {
        this.showToast = false
        this.toastText = ''
    }

    @action setIsDark(value: boolean) {
        this.isDark = value
    }

    constructor(public root: RootStore) {
        // Use matchMedia to check the user preference
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
        this.setIsDark(prefersDark.matches)

        // Listen for changes to the prefers-color-scheme media query
        prefersDark.addListener((mediaQuery) => this.setIsDark(mediaQuery.matches))
    }
}
