import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonInput,
    IonRange,
    IonRow,
    IonToggle,
} from '@ionic/react'
import { getDevices, patchSetting } from '../requests/Devices'
import { sleep, useStores } from '../util/Functions'
import { ModelSetting } from '../util/Models'

interface specificDeviceSetting {
    setting: ModelSetting
}

export const CompSetting: React.FC<specificDeviceSetting> = observer((specific) => {
    console.debug(toJS(specific.setting))

    // load mobx stores
    const { root, data, ui } = useStores()

    // init form  variables
    const [valueStr, setValueStr] = useState<string>(specific.setting.value)
    const [valueNum, setValueNum] = useState<number>(parseFloat(specific.setting.value))

    // refresh page
    if (ui.needsRefresh) {
        getDevices(root)
        if (specific.setting.value !== valueStr) {
            setValueStr(specific.setting.value)
        }
        ui.setNeedsRefresh(false)
    }

    // init form
    let formData: any = null
    if (!specific.setting.rangemin) {
        formData = {
            value: valueStr,
        }
    } else {
        formData = {
            value: valueNum,
        }
    }

    // handles
    const handleButtonSetting = async () => {
        console.debug('disable button')
        setButtonDisabled(true)
        await patchSetting(root, specific.setting.id, formData)
        await getDevices(root)
        await sleep(100)
        console.debug(toJS(data.devices))
        console.debug('enable button')
        setButtonDisabled(false)
    }
    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') handleButtonSetting()
    }

    // page behaviour
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

    return (
        <IonCard>
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>{specific.setting.label}</IonCol>
                        <IonCol>
                            {specific.setting.gui === 'form' ? (
                                <IonInput
                                    value={valueStr}
                                    onIonChange={(e: any) => setValueStr(e.detail.value!)}
                                    onKeyDown={handleKeyPress}
                                    placeholder="specific.setting.value"
                                />
                            ) : (
                                ''
                            )}
                            {specific.setting.gui === 'toggle' ? (
                                <IonToggle
                                    value={valueStr}
                                    checked={valueStr === 'on'}
                                    placeholder={specific.setting.value}
                                    onIonChange={(e: any) => {
                                        if (e.detail.checked) {
                                            setValueStr('on')
                                        } else {
                                            setValueStr('off')
                                        }
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {specific.setting.gui === 'range' ? (
                                <IonRange
                                    min={specific.setting.rangemin}
                                    max={specific.setting.rangemax}
                                    step={2}
                                    snaps={true}
                                    value={valueNum}
                                    onIonChange={(e: any) => setValueNum(e.detail.value!)}
                                    onKeyDown={handleKeyPress}
                                    placeholder="specific.setting.value"
                                />
                            ) : (
                                ''
                            )}
                        </IonCol>

                        <IonCol>
                            <IonButton
                                disabled={buttonDisabled}
                                onClick={() => {
                                    handleButtonSetting()
                                }}
                            >
                                OK
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
})
