import { cloudDoneOutline, cloudOfflineOutline } from 'ionicons/icons'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { getLiveRecords } from '../requests/LiveRecords'
import { usePageVisibility, useStores } from '../util/Functions'
import { RootStore } from '../util/stores/RootStore'

export const WebsocketButton: React.FC = observer(() => {
    const rootStore = useStores()
    const { data, ui, user } = rootStore
    const disconnected = ui.colourWebsocket !== 'success'

    const isVisible = usePageVisibility()

    useEffect(() => {
        if (isVisible && disconnected && user.authenticated) {
            console.debug('Re-establishing websocket')
            getLiveRecords(rootStore as RootStore)
            data.refreshWebsocket()
        }
    })

    return (
        <IonButton
            onClick={() => {
                if (disconnected) data.refreshWebsocket()
            }}
        >
            <IonIcon
                slot="icon-only"
                icon={disconnected ? cloudOfflineOutline : cloudDoneOutline}
                color={ui.colourWebsocket}
            />
        </IonButton>
    )
})
