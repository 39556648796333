import axios, { AxiosResponse } from 'axios'
import { handleError } from '../util/Functions'
import { RootStore } from '../util/stores/RootStore'

/* Request handle */
export const postRegistration = async (root: RootStore, formData: any) => {
    /* Init */
    let response: AxiosResponse

    /* Url */
    const url = root.cloud.host + '/api/accounts/register/'
    console.debug('Trying to register (post new user to) ' + url)

    /* Request handle */
    try {
        response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: false,
            timeout: root.data.timeOutPost,
        })
        console.debug(response)
        root.user.setRegisterSucces(true)
    } catch (error) {
        handleError(root, error)
    }
}
