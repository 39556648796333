import axios, { AxiosResponse } from 'axios'
import { handleError } from '../util/Functions'
import { RootStore } from '../util/stores/RootStore'

/* Request handle */
export const getDevices = async (root: RootStore) => {
    /* Init */
    let response: AxiosResponse

    /* Urls */
    const url = root.cloud.host + '/api/devices/device/?format=json'
    console.debug('Trying to get devices from: ' + url)

    /* Request handle */
    try {
        response = await axios.get(url, {
            headers: {
                Authorization: 'Token ' + root.user.token,
                'Content-Type': 'application/json',
            },
            timeout: root.data.timeOutGet,
        })

        const devices = response.data.results
        // console.debug(devices)
        root.data.setDevices(devices)
        console.debug(`Loaded device list with ${root.data.devices.length} items`)
    } catch (error) {
        handleError(root, error)
    }
}

/* Request handle */
export const patchDeviceDescription = async (root: RootStore, deviceid, formData) => {
    /* Init */
    let response: AxiosResponse

    /* Url */
    const url = root.cloud.host + '/api/devices/device/' + deviceid + '/'
    console.log('Trying to patch address confirmed to ' + url)

    /* Request handle */
    try {
        response = await axios.patch(url, formData, {
            headers: {
                Authorization: 'Token ' + root.user.token,
                'Content-Type': 'application/json',
            },
            timeout: root.data.timeOutPost,
        })
        console.debug(response)
    } catch (error) {
        handleError(root, error)
    }
}

/* Request handle */
export const patchSetting = async (root: RootStore, settingid, formData) => {
    /* Init */
    let response: AxiosResponse

    /* Url */
    const url = root.cloud.host + '/api/devices/setting/' + settingid + '/'
    console.log('Trying to patch address confirmed to ' + url)

    /* Request handle */
    try {
        response = await axios.patch(url, formData, {
            headers: {
                Authorization: 'Token ' + root.user.token,
                'Content-Type': 'application/json',
            },
            timeout: root.data.timeOutPost,
        })
        console.debug(response)
    } catch (error) {
        handleError(root, error)
    }
}
