import Cookies, { Cookie } from 'universal-cookie'
import { getDevices } from '../../requests/Devices'
import { getLiveRecords } from '../../requests/LiveRecords'
import { getRecords } from '../../requests/Records'
import { getUpdates } from '../../requests/Updates'
import { getUserRecords } from '../../requests/UserRecords'
import { sleep } from '../Functions'
import { CloudStore } from './CloudStore'
import { DataStore } from './DataStore'
import { UIStore } from './UIStore'
import { UserStore } from './UserStore'

/* Rootstore, containing all other stores */
export class RootStore {
    public data: DataStore
    public cloud: CloudStore
    public user: UserStore
    public ui: UIStore

    public cookies: Cookie

    constructor() {
        this.cookies = new Cookies()
        this.cloud = new CloudStore(this)
        this.user = new UserStore(this)
        this.data = new DataStore(this)
        this.ui = new UIStore(this)
    }

    loadAllData = async () => {
        getUpdates(this)
        getDevices(this)
        getUserRecords(this)
        getRecords(this)
        getLiveRecords(this)
        await sleep(2000)
        this.data.refreshWebsocket() // establish stream
    }
}
