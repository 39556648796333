import { IonContent, IonPage } from '@ionic/react'

import { DeviceSummary } from '../components/DeviceSummary'
import { Loading } from '../components/Loading'
import { ModelDevice } from '../util/Models'
import { PageHeader } from '../components/PageHeader'
import React from 'react'
import { Refresher } from '../components/Refresher'
import { createUseStyles } from 'react-jss'
import { observer } from 'mobx-react'
import { useStores } from '../util/Functions'

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        alignContent: 'flex-start',
        maxWidth: 900,
        margin: '0 auto',
        padding: '5px 10px',
        '@media (min-width: 768px)': {
            flexWrap: 'wrap',
            flexDirection: 'row',
            padding: '10px 20px',
        },
    },
})

export const Home: React.FC = observer(() => {
    const { data } = useStores()
    const classes = useStyles()

    // Populate home screen with device summary cards
    const devices = data.devices.map((device: ModelDevice) => (
        <DeviceSummary device={device} key={device.id} />
    ))

    const pageid = 'Home'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={true} pagetitle={pageid} />
            <IonContent fullscreen={true}>
                {devices.length > 0 ? (
                    <div className={classes.container}>
                        <Refresher />
                        {devices}
                    </div>
                ) : (
                    <Loading />
                )}
            </IonContent>
        </IonPage>
    )
})
