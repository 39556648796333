import axios, { AxiosResponse } from 'axios'
import { handleError } from '../util/Functions'
import { RootStore } from '../util/stores/RootStore'

/* Request handle */
export const getLiveRecords = async (root: RootStore) => {
    /* Init */
    let response: AxiosResponse

    /* Urls */
    const url = root.cloud.host + '/api/devices/liverecord/?format=json'
    console.debug('Trying to get liveRecords from: ' + url)

    /* Request handle */
    try {
        response = await axios.get(url, {
            headers: {
                Authorization: 'Token ' + root.user.token,
                'Content-Type': 'application/json',
            },
            timeout: root.data.timeOutGet,
        })

        const liveRecords = response.data.results
        // console.debug(liveRecords)
        root.data.setLiveRecords(liveRecords)
        console.debug(`Loaded live record list with ${root.data.liveRecords.length} items`)
    } catch (error) {
        handleError(root, error)
    }
}
