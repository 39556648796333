import { checkmarkCircle, closeCircle } from 'ionicons/icons'
import { observer } from 'mobx-react'
import React from 'react'
import {
    IonContent,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonPage,
} from '@ionic/react'
import { PageHeader } from '../components/PageHeader'
import { useStores } from '../util/Functions'
import { ModelDevice } from '../util/Models'

interface SpecificDevice {
    device: ModelDevice
}

const ItemDevice: React.FC<SpecificDevice> = observer((props) => {
    return (
        <IonItem routerLink={`/devices/${props.device.id}`}>
            <IonIcon
                slot="start"
                icon={props.device.online ? checkmarkCircle : closeCircle}
                color={props.device.online ? 'success' : 'danger'}
            />
            <IonLabel>{props.device.description}</IonLabel>
            <p>({props.device.id})</p>
        </IonItem>
    )
})

export const DeviceList: React.FC = observer(() => {
    const { data } = useStores()

    // render devices only if there are any
    let devicesToRender: any
    if (data.devices.length > 0) {
        devicesToRender = data.devices.map((device: ModelDevice) => (
            <ItemDevice device={device} key={device.id} />
        ))
    }

    const pageid = 'DeviceList'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={false} pagetitle="Devices" />
            <IonContent fullscreen={true}>
                <IonItemGroup>
                    <IonItemDivider>
                        <IonLabel>Available devices</IonLabel>
                    </IonItemDivider>
                    <IonList>{devicesToRender}</IonList>
                </IonItemGroup>
            </IonContent>
        </IonPage>
    )
})
