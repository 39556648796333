import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    IonButton,
    IonCol,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonToast,
} from '@ionic/react'
import { PageHeader } from '../components/PageHeader'
import { postRegistration } from '../requests/Registration'
import { useStores } from '../util/Functions'

export const Signup: React.FC = observer(() => {
    /* Load */
    const { root, ui } = useStores()
    const history = useHistory()

    /* Init form  variables*/
    const [username, setUsername] = useState<string>()
    const [password, setPassword] = useState<string>()
    const [passwordconfirm, setPasswordconfirm] = useState<string>()
    const [firstname, setFirstname] = useState<string>()
    const [lastname, setLastname] = useState<string>()
    const [email, setEmail] = useState<string>()

    /* Init form */
    const formData = {
        username: username,
        password: password,
        password_confirm: passwordconfirm,
        first_name: firstname,
        last_name: lastname,
        email: email,
    }

    /* Handle submit */
    const handleSubmitForm = async () => {
        await postRegistration(root, formData)
        setToastMessage(ui.postResponseText)
        setShowToast(true)
        if (root.user.registerSucces) {
            history.push('/auth')
        }
    }

    /* Page behaviour */
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState<string>()

    const pageid = 'Signup'
    return (
        <IonPage id={pageid}>
            <PageHeader pageid={pageid} pagemain={false} pagetitle={`Sign Up`} />
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonLabel position="fixed" color="primary">
                            User
                        </IonLabel>
                        <IonInput
                            value={username}
                            placeholder="here.."
                            onIonChange={(e: any) => setUsername(e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="fixed" color="primary">
                            Password
                        </IonLabel>
                        <IonInput
                            value={password}
                            type="password"
                            placeholder="at least 8 char, not too simple.."
                            onIonChange={(e: any) => setPassword(e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="fixed" color="primary">
                            Confirm password
                        </IonLabel>
                        <IonInput
                            value={passwordconfirm}
                            type="password"
                            placeholder="repeat password.."
                            onIonChange={(e: any) => setPasswordconfirm(e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="fixed" color="primary">
                            First name
                        </IonLabel>
                        <IonInput
                            value={firstname}
                            placeholder="optional.."
                            onIonChange={(e: any) => setFirstname(e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="fixed" color="primary">
                            Last name
                        </IonLabel>
                        <IonInput
                            value={lastname}
                            placeholder="optional.."
                            onIonChange={(e: any) => setLastname(e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="fixed" color="primary">
                            Email
                        </IonLabel>
                        <IonInput
                            value={email}
                            placeholder="optional.."
                            onIonChange={(e: any) => setEmail(e.detail.value!)}
                        />
                    </IonItem>
                </IonList>
                <IonRow>
                    <IonCol>
                        <IonButton
                            type="submit"
                            expand="block"
                            onClick={() => {
                                handleSubmitForm()
                            }}
                        >
                            Submit
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonContent>
            <IonToast
                isOpen={showToast}
                duration={10000}
                message={toastMessage}
                onDidDismiss={() => {
                    setShowToast(false)
                    ui.setPostResponseText('')
                }}
            />
        </IonPage>
    )
})
