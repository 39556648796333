import axios, { AxiosResponse } from 'axios'

import { RootStore } from '../util/stores/RootStore'
import { handleError } from '../util/Functions'

/* Request handle */
export const getRecords = (root: RootStore) => {
    /* Urls */
    const baseUrl = root.cloud.host + '/api/devices/record/?format=json&page='

    const queryRecords = (page: number) => {
        /* Request handle */
        console.debug(baseUrl + page)
        axios
            .get(baseUrl + page, {
                headers: {
                    Authorization: 'Token ' + root.user.token,
                    'Content-Type': 'application/json',
                },
                timeout: root.data.timeOutGet,
            })
            .then((response: AxiosResponse) => {
                const records = response.data.results
                root.data.setRecords(records)

                if (response.data.next !== null) {
                    queryRecords(page + 1)
                } else {
                    root.data.updateRecords()
                }
            })
            .catch((error) => {
                handleError(root, error)
            })
    }

    queryRecords(1)
}
