import * as Plotly from 'plotly.js-dist'

import { ModelDevice, ModelLiveRecord, ModelLiveVariable, ModelRecord } from '../util/Models'

import { DateTime } from 'luxon'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { observer } from 'mobx-react'
import { useStores } from '../util/Functions'

const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        height: '50vh',
    },
    plot: {
        flex: 1,
        '@media (min-width:1000px)': {
            maxWidth: 900,
        },
        zIndex: 100,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
        maxWidth: 600,
    },
})

export interface PlotlyPlotProps {
    data?: Plotly.Data[]
    layout: Partial<Plotly.Layout>
    frames?: Plotly.Frame[]
    config?: Partial<Plotly.Config>
    style?: React.CSSProperties
}

export const PlotlyPlot: React.FC<PlotlyPlotProps> = ({ style = {}, data, ...props }) => {
    const ref = React.useRef<HTMLDivElement>(null)
    React.useEffect(() => {
        Plotly.react(ref.current, { data, ...props })
    }, [props, data])

    return <div ref={ref} style={style} />
}

const colorway = [
    '#ebac23',
    '#008cf9',
    '#b80058',
    '#00a76c',
    '#d163e6',
    '#00bbad',
    '#b24502',
    '#ff9287',
    '#5954d6',
    '#00c6f8',
    '#878500',
    '#006e00',
]

interface PlotProps {
    records: Map<string, ModelRecord[]>
    unit: string
    variable: string
    tickSize?: number
}

export const Plot: React.FC<PlotProps> = observer((props) => {
    const [show, setShow] = React.useState(false)
    const { data, ui } = useStores()
    const classes = useStyles()

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true)
        }, 10)

        return () => clearTimeout(timeout)
    })

    // Live records
    const deviceRecords = new Map(props.records)
    const getLiveVariables = () => {
        const variables: Map<string, ModelLiveVariable> = new Map()
        const liveRecords = data.liveRecords.filter((item: ModelLiveRecord) => {
            return item.variable === props.variable
        })

        liveRecords.forEach((liveRecord) => {
            const device: ModelDevice = data.devices.filter((item: ModelDevice) => {
                return item.id === liveRecord.device
            })[0]

            if (device) {
                const liveVariable: ModelLiveVariable = {
                    device: device,
                    liveRecord: liveRecord,
                }
                variables.set(device.id, liveVariable)
            }
        })

        return variables
    }

    // Get datasets
    const liveVariables = getLiveVariables()
    let datasets: any[] = []
    const windowSize = 5
    let traceInd = 0

    let xMin = Infinity
    let xMax = -Infinity

    let valueMin = Infinity
    let valueMax = -Infinity

    let minTS: DateTime
    let maxTS: DateTime

    for (const [device, records] of deviceRecords.entries()) {
        const name = data.deviceDescription.get(device)
        const liveValue =
            device && liveVariables.has(device) ? liveVariables.get(device)?.liveRecord.value : null

        let xData: string[] = []
        let yData: number[] = []
        let textData: string[] = []

        for (let ind = 0; ind < records.length; ind++) {
            let windowValues: number[] = []
            const min = ind - windowSize
            const max = ind + windowSize
            for (let i = min; i < max; i++) {
                if (i < 0) {
                    windowValues.push(records[0].value)
                } else if (i > records.length - 1) {
                    windowValues.push(records[records.length - 1].value)
                } else {
                    windowValues.push(records[i].value)
                }
            }

            const windowMean =
                windowValues.reduce((prev: number, curr: number) => prev + curr) /
                windowValues.length

            xData.push(records[ind].timestamp.toFormat('yyyy-MM-dd HH:mm:ss'))
            yData.push(Number(windowMean.toFixed(2)))
            textData.push(
                ind === records.length - 1 && liveValue ? ` ${liveValue + props.unit}` : ''
            )

            if (windowMean < valueMin) valueMin = windowMean
            if (windowMean > valueMax) valueMax = windowMean
        }

        datasets.push({
            name: name,
            x: xData,
            y: yData,
            type: 'scatter',
            mode: 'lines+text',
            line: {
                shape: 'spline',
                smoothing: 1.1,
            },
            text: textData,
            textposition: 'middle right',
            textfont: {
                size: 14,

                color: colorway[traceInd],
            },
            legendgroup: name,
        })

        if (records[0].timestamp.ts < xMin) {
            xMin = records[0].timestamp.ts
            minTS = new DateTime(records[0].timestamp)
        }

        const lastInd = records.length - 1
        if (records[lastInd].timestamp.ts > xMax) {
            xMax = records[lastInd].timestamp.ts
            maxTS = new DateTime(records[lastInd].timestamp)
        }

        traceInd++
    }

    // Dynamic right plot axis padding to accomodate text
    const paddingHours = props.variable === 'CarbonDioxide' ? 8 : 5
    maxTS = maxTS?.plus({ hours: paddingHours })

    // Y-axis scaling
    const tickSize = props.tickSize ? props.tickSize : 5

    let yMin = Math.floor(valueMin / tickSize) * tickSize
    let yMax = Math.ceil(valueMax / tickSize) * tickSize

    if (props.variable === 'CarbonDioxide') {
        yMin = 0
        yMax = Math.max(yMax, 1200)
    }

    const numTicks = (yMax - yMin) / tickSize + 1

    // Plot styling
    const axisLabelColor = ui.isDark ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.8)'
    const gridColor = ui.isDark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

    const plotConfig = {
        displaylogo: false,
        responsive: true,
        modeBarButtonsToRemove: ['select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d'],
        displayModeBar: false,
    }

    console.log(props.variable)

    // Show plot or loading screen
    return show ? (
        <div className={classes.container}>
            <div className={classes.plot}>
                <PlotlyPlot
                    data={datasets}
                    layout={{
                        margin: {
                            t: 32,
                            r: 0,
                            b: 0,
                            l: props.variable === 'CarbonDioxide' ? 60 : 40,
                        },
                        paper_bgcolor: 'rgba(0,0,0,0)',
                        plot_bgcolor: 'rgba(0,0,0,0)',
                        colorway: colorway,
                        xaxis: {
                            range: [
                                minTS?.toFormat('yyyy-MM-dd HH:mm:ss'),
                                maxTS?.toFormat('yyyy-MM-dd HH:mm:ss'),
                            ],
                            color: axisLabelColor,
                            gridcolor: gridColor,
                            linecolor: gridColor,
                            showgrid: true,
                            showline: true,
                            tickformat: '%H:%S',
                            nticks: 6,
                        },
                        yaxis: {
                            range: [yMin, yMax],
                            showgrid: false,
                            showticklabels: true,
                            ticksuffix: props.unit,
                            nticks: numTicks,
                        },
                        legend: {
                            orientation: 'h',
                            font: {
                                size: 14,
                                color: axisLabelColor,
                            },
                            tracegroupgap: 10,
                            y: -0.2,
                        },
                    }}
                    style={{ width: '100%', height: '50vh' }}
                    config={plotConfig}
                />
            </div>
        </div>
    ) : null
})
